import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import LatestWorkLayout from "../../templates/latestWork/LatestWork.component.js";
export const _frontmatter = {
  "title": "Website for Akari Center",
  "path": "/latest-works/akariweb",
  "date": "2020-09-18T00:00:00.000Z",
  "description": "A website created for Akari Skills Training Center for Children With Special Needs. It is coupled with a CMS to allow the users to upload their posts later. The site is built with Gatsby and NetlifyCMS.",
  "img": "akariweb.png",
  "imgAlt": "Akari website image",
  "url": "https://akaricenter.netlify.app/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LatestWorkLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>A web page with an integrated CMS</h1>
    <p>{props.pageContext.frontmatter.description}</p>
    <h2>{`Main features:`}</h2>
    <ul>
      <li parentName="ul">{`Using NetlifyCMS to allow users to upload posts`}</li>
      <li parentName="ul">{`Hosted on Netlify`}</li>
      <li parentName="ul">{`Contact form is tied with Google Sheet`}</li>
      <li parentName="ul">{`Responsive design`}</li>
    </ul>
    <h2>{`Main incorporated libraries:`}</h2>
    <ul>
      <li parentName="ul">{`Gatsby`}</li>
      <li parentName="ul">{`NetlifyCMS`}</li>
      <li parentName="ul">{`Tachyons`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      